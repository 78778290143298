import React, { useRef, useState } from 'react';
import cn from 'classnames';
import { useIsomorphicLayoutEffect } from '@lib/hooks/useIsomorphicLayoutEffect';
import helper from '@utils/helper';
import Link from 'next/link';
import AvatarComponent from '@components/AvatarComponent';
import { getClient } from '@api/feedStream';
import { FollowBtn } from '@widget/community';
import styles from './index.module.less';
import I18n from '@utils/i18n';
import { IMG_AND_VIDEO } from '@utils/reg';
import CellTradingInfo from '@widget/post/Details/Components/CellTradingInfo';

const HeaderItemText = ({ itemData, showText }) => {
  const [showTextMore, setShowTextMore] = useState(false);
  const feedContentRef = useRef();
  const feedContainerRef = useRef();

  useIsomorphicLayoutEffect(() => {
    if (
      feedContentRef?.current?.scrollHeight >
      feedContainerRef?.current?.scrollHeight
    ) {
      setShowTextMore(true);
    }
  }, []);

  const { subVerb } = itemData;
  const showTextLen = showText.length;
  let formatSummary = `<p>${showText
    ?.replace(IMG_AND_VIDEO, '')
    .slice(0, 300)}<p>`;
  if (showTextLen >= 300) {
    formatSummary = formatSummary + '...';
  }
  if (subVerb === '15') {
    return (
      <Link href={'/post/' + itemData.id}>
        <a>
          <CellTradingInfo itemData={itemData} type="feed" />
        </a>
      </Link>
    );
  } else {
    return (
      <div className={styles.headerItemTextContainer}>
        <Link href={'/post/' + itemData.id}>
          <a>
            <>
              <div className={styles.headerItemText} ref={feedContainerRef}>
                <div
                  className={cn(styles.headerItemTextContainer, 'DINPro')}
                  // className="DINPro"
                  dangerouslySetInnerHTML={{ __html: formatSummary }}
                  id="feedContent"
                  ref={feedContentRef}
                />
              </div>
              {showTextMore || showTextLen >= 300
                ? (
                <span className={styles.showTextMore}>
                  {I18n.t('K9GJbNWK')}
                </span>
                  )
                : null}
            </>
          </a>
        </Link>
      </div>
    );
  }
};

export const HeaderItem = ({ itemData, router: { pathname } }) => {
  const pageTitleDom = (pathname, title) => {
    switch (pathname) {
      case '/community':
        return <h3 className={'DINPro-Medium'}>{title.replace(/\n/g, '')}</h3>;

      case '/':
        return <h4 className={'DINPro-Medium'}>{title.replace(/\n/g, '')}</h4>;

      default:
        break;
    }
  };
  const { customerMisc } = itemData;
  const { title, text, summary = '' } = itemData;
  const client = getClient();
  const showText = summary || text || '';

  let nickName;
  let avatar = null;
  let customerId;
  if (customerMisc) {
    nickName = !customerMisc?.nickName ? '' : customerMisc.nickName;
    customerId = !customerMisc?.customerId ? '' : customerMisc.customerId;
    avatar = customerMisc.avatar;
  }

  if (customerMisc && nickName !== null) {
    const identificationSource = helper.identificationSwitch(customerMisc.role);
    return (
      <div className={styles.headerContainer}>
        <div>
          <div className={styles.headerMain}>
            <div className={styles.headerMainLeft}>
              <AvatarComponent
                customerId={customerId}
                avatar={avatar}
                avatarSize={40}
                identificationSource={identificationSource}
                identificationSize={14}
              />
              <Link href={'/post/' + itemData.id}>
                <a>
                  <div className={styles.infoContainer}>
                    <span className={styles.level}>
                      <div className={'DINPro-Medium'}>
                        {customerMisc?.nickName ?? ''}
                      </div>
                    </span>
                    <div className={styles.timeContainer}>
                      <div className={styles.time}>
                        {helper.toLocaleTime(itemData.timestamp, 'MM-DD HH:mm')}
                      </div>
                      {itemData.customerMisc?.intro
                        ? (
                        <div className={styles.intro}>
                          {itemData.customerMisc?.intro.split('\n')[0]}
                        </div>
                          )
                        : null}
                    </div>
                  </div>
                </a>
              </Link>
            </div>
            {client.userId !== customerMisc?.customerId && (
              <FollowBtn
                type="ghost"
                size="md"
                followedHidden
                isFollow={customerMisc?.follow}
                toCustomerId={customerMisc?.customerId}
                className={styles.followBtn}
              />
            )}
          </div>
        </div>
        {title
          ? (
          <Link href={'/post/' + itemData.id}>
            <a>
              <div className={styles.titleContainer}>
                <span className={styles.titleText}>
                  {pageTitleDom(pathname, itemData.title)}
                </span>
              </div>
            </a>
          </Link>
            )
          : null}
        {showText
          ? (
          <HeaderItemText itemData={itemData} showText={showText} />
            )
          : (
          <div className={styles.nullTitle} />
            )}
      </div>
    );
  } else {
    return <div />;
  }
};
